import React from 'react';
import Fade from 'react-reveal/Fade';
import Container from '../Layout/Container';
import { Linkedin } from '../Misc/SVG';

const Team = ({ data }) => {
  let memberLength = data.members.length + 1

  return (
    <section id="team">
      <Container>

        <h2>{data.section_title.text}</h2>
        
        <ul className="team-wrapper">
          {data.members.map((member, i) => {
            
            return (
              <Fade key={i} bottom duration={1200} delay={i*100} distance={100-(i*10) + "px"}>
                <li className="team-item">
                  <div className="team-item-bubble">
                    <div className="cover">
                      <div className="img-position">
                        <img src={member.photo.url} alt={member.name} />
                      </div>
                      <div className="active-color" style={{backgroundColor: member.color}}></div>
                    </div>

                    {(() => {
                      if (member.linkedin_url.url) {
                        return (
                          <div className="linkedin">
                            <a href={member.linkedin_url.url} target="_blank" rel="noopener" aria-label={member.name + " Linkedin profile"}>
                              <Linkedin/>
                            </a>
                          </div>
                        )
                      }
                    })()}
                    
                  </div>
                  <div className="team-item-info">
                    <h3>{member.name}</h3>
                    <small>{member.role}</small>
                  </div>
                </li>
              </Fade>
            );
          })}

          <Fade key={memberLength} bottom duration={1200} delay={memberLength*100} distance={100-(memberLength*10) + "px"}>
            <li className="team-item join">
              <a href="https://join.whypartners.com/">
                <div className="team-item-bubble">
                  <div className="cover">
                    <h2>?</h2>
                  </div>
                </div>
                <div className="team-item-info">
                  <h3>You?</h3>
                  <small>Check out our open positions</small>
                </div>
              </a>
            </li>
          </Fade>
        </ul>

      </Container>
    </section>
  );
};

export default Team;
