import React from 'react';
import Container from '../Layout/Container';
import Fade from 'react-reveal/Fade';
import ReactTypingEffect from '../../vendor/react-typing-effect';

const Hero = ({ data }) => {
  
  const typeSpeed = 100
  const eraseSpeed = 40
  const typingDelay = 100
  const titleTags = []
  const titleColors = []
  for(var i in data.title_tag) {
    titleTags.push(data.title_tag[i].tag)
    titleColors.push(data.title_tag[i].color)
  }

  return (
    <section id="hero">
      <Container>
        <Fade bottom duration={1000} delay={600} distance="50px">
          <h1 className="title">
            {data.title_pre}{' '}
            <br className="break"/>
            <ReactTypingEffect
              text={titleTags}
              cursorClassName="cursor"
              speed={typeSpeed}
              eraseSpeed={eraseSpeed}
              typingDelay={typingDelay}
              displayTextRenderer={(text, i) => {
                return (
                  <div
                    className="title-tag"
                    style={{color: titleColors[i]}}
                  >
                    {text}
                    <span
                      className="marker"
                      style={{backgroundColor: titleColors[i]}}
                    ></span>
                  </div>
                );
              }}/>
            <br />
            {data.title_post}
          </h1>
        </Fade>
        <Fade bottom duration={1000} delay={1000} distance="30px">
          <p className="lead">
            {data.lead}
          </p>
        </Fade>
      </Container>
    </section>
  );
};

export default Hero;
