import React from 'react';
import Fade from 'react-reveal/Fade';
import Container from '../Layout/Container';

const Contact = ({ data }) => {
	return (
		<section id="contact">
			<Container>
				<a className="contact-wrapper" rel="noopener noreferrer" target="_blank" href={"mailto:" + data.contact_email}>
					<Fade bottom duration={1000} delay={2800} distance="10px">
						<div className="contact-link">
							{data.contact_cta}
						</div>
					</Fade>
				</a>
			</Container>
		</section>
	);
};

export default Contact;