import React from 'react';
import { Link } from 'gatsby';
import Pulse from 'react-reveal/Pulse';

const Logo = () => {
  return (
    <Pulse duration={1400}>
      <div id="logo">
        <Link aria-label="Home" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
            <g className="logo">
              <circle cx="40" cy="40" r="36.2"/>
              <path d="M28.25,32.5c0-6.49,5.26-11.75,11.75-11.75s11.75,5.26,11.75,11.75S46.49,44.25,40,44.25h-3.8"/>
              <circle cx="40" cy="58.2" r="1"/>
            </g>
          </svg>
        </Link>
      </div>
    </Pulse>
  );
};

export default Logo;
