import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxProvider } from 'react-scroll-parallax';

import Logo from './Logo';
import Footer from './Footer';
import '../../style/main.scss';

const Layout = ({ nologo, children }) => {
  return (
    <>
      {(() => {
        if (!nologo) {
          return <Logo />;
        }
        return false;
      })()}
      <ParallaxProvider>
        <main id="root">{children}</main>
      </ParallaxProvider>
      <Footer />
    </>
  );
};

export default Layout;

Layout.propTypes = {
  nologo: PropTypes.object,
  children: PropTypes.object.isRequired,
};
