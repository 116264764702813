import React from 'react';
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import SEO from "../components/Seo"
import Layout from '../components/Layout';
import Hero from '../components/Index/Hero';
import Partners from '../components/Index/Partners';
import Contact from '../components/Index/Contact';
import Team from '../components/Index/Team';

const IndexPage = ({ data: { prismicHome } }) => {
  const {data} = prismicHome

  return (
    <>
      <SEO title={data.meta_title} />
      <Layout>
        <Hero data={data}/>
        <Partners data={data}/>
        <Contact data={data}/>
        <Team data={data} />
      </Layout>
    </>
  );
}

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query {
    prismicHome {
      data {
        contact_cta
        contact_email
        lead
        meta_description
        meta_title
        partner {
          name
          svg_logo {
            url
            alt
          }
        }
        title_post
        title_pre
        members {
          color
          name
          role
          linkedin_url {
            url
          }
          photo {
            url
            alt
          }
        }
        section_title {
          text
        }
        title_tag {
          color
          tag
        }
      }
    }
  }
`