import React from 'react';
import Fade from 'react-reveal/Fade';
import Container from '../Layout/Container';

const Partners = ({ data }) => {
  const partnersLength = data.partner.length

  return (
    <section id="partners">
      <Container>
        <ul className="partner-wrapper">
          {data.partner.map((partner, i) => {
            return (
              <React.Fragment key={i}>
                <Fade key={i} bottom duration={1200} delay={1400 + (i*100)} distance={100-(i*10) + "px"}>
                  <li className="parner-item">
                    <img src={partner.svg_logo.url} alt={partner.name}/>
                  </li>
                </Fade>
                {(() => {
                  if (partnersLength > i + 1) {
                    return (
                      <li className="parner-spacer"></li>
                    )
                  }
                })()}
              </React.Fragment>
            );
          })}
        </ul>
      </Container>
    </section>
  );
};

export default Partners;
