import React from 'react';

const LinkedinSquare = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
		<path d="M23.8,0H6.2C2.8,0,0,2.8,0,6.2v17.5C0,27.2,2.8,30,6.2,30h17.5c3.5,0,6.2-2.8,6.2-6.2V6.2C30,2.8,27.2,0,23.8,0
			z M10,23.8H6.2V10H10V23.8z M8.1,8.4c-1.2,0-2.2-1-2.2-2.2S6.9,4,8.1,4s2.2,1,2.2,2.2S9.3,8.4,8.1,8.4z M25,23.8h-3.8v-7
			c0-4.2-5-3.9-5,0v7h-3.8V10h3.8v2.2C18,9,25,8.7,25,15.3V23.8z"/>
  </svg>
);

const Linkedin = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
		<path d="M10,23.8H6.2V10H10V23.8z M8.1,8.4c-1.2,0-2.2-1-2.2-2.2S6.9,4,8.1,4s2.2,1,2.2,2.2S9.3,8.4,8.1,8.4z M25,23.8
			h-3.8v-7c0-4.2-5-3.9-5,0v7h-3.8V10h3.8v2.2C18,9,25,8.7,25,15.3V23.8z"/>
  </svg>
);

const Instagram = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
  	<path d="M29.9,8.8c-0.1-1.6-0.3-2.7-0.7-3.6c-0.4-1-1-1.9-1.7-2.7c-0.8-0.8-1.7-1.4-2.7-1.7c-1-0.4-2-0.6-3.6-0.7
			C19.6,0,19.1,0,15,0s-4.6,0-6.2,0.1C7.2,0.2,6.1,0.4,5.2,0.8c-1,0.4-1.9,1-2.7,1.7C1.8,3.3,1.2,4.2,0.8,5.2c-0.4,1-0.6,2-0.7,3.6
			C0,10.4,0,10.9,0,15s0,4.6,0.1,6.2c0.1,1.6,0.3,2.7,0.7,3.6c0.4,1,1,1.9,1.7,2.7c0.8,0.8,1.7,1.4,2.7,1.7c1,0.4,2,0.6,3.6,0.7
			C10.4,30,10.9,30,15,30s4.6,0,6.2-0.1c1.6-0.1,2.7-0.3,3.6-0.7c2-0.8,3.6-2.4,4.4-4.4c0.4-1,0.6-2,0.7-3.6C30,19.6,30,19.1,30,15
			S30,10.4,29.9,8.8z M27.2,21.1c-0.1,1.5-0.3,2.3-0.5,2.8c-0.5,1.3-1.5,2.3-2.8,2.8c-0.5,0.2-1.3,0.5-2.8,0.5
			c-1.6,0.1-2.1,0.1-6.1,0.1s-4.5,0-6.1-0.1c-1.5-0.1-2.3-0.3-2.8-0.5c-0.7-0.2-1.2-0.6-1.7-1.1c-0.5-0.5-0.9-1.1-1.1-1.7
			c-0.2-0.5-0.5-1.3-0.5-2.8C2.7,19.5,2.7,19,2.7,15c0-4,0-4.5,0.1-6.1c0.1-1.5,0.3-2.3,0.5-2.8c0.2-0.7,0.6-1.2,1.1-1.7
			C4.9,4,5.5,3.6,6.2,3.3C6.7,3.1,7.5,2.9,9,2.8C10.5,2.7,11,2.7,15,2.7c4,0,4.5,0,6.1,0.1c1.5,0.1,2.3,0.3,2.8,0.5
			c0.7,0.2,1.2,0.6,1.7,1.1c0.5,0.5,0.9,1.1,1.1,1.7c0.2,0.5,0.5,1.3,0.5,2.8c0.1,1.6,0.1,2.1,0.1,6.1C27.3,19,27.3,19.5,27.2,21.1z
			 M15,7.3c-4.3,0-7.7,3.5-7.7,7.7c0,4.3,3.5,7.7,7.7,7.7c4.3,0,7.7-3.5,7.7-7.7C22.7,10.8,19.3,7.3,15,7.3z M15,20
			c-2.8,0-5-2.2-5-5c0-2.8,2.2-5,5-5c2.8,0,5,2.2,5,5C20,17.8,17.8,20,15,20z M23,5.2c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8
			c1,0,1.8-0.8,1.8-1.8C24.8,6,24,5.2,23,5.2z"/>
  </svg>
);

export { LinkedinSquare, Linkedin, Instagram }