import React from 'react';

const Container = ({ full, children }) => {
  return (
    <div className={!full ? 'container' : 'container--full'}>
      {children}
    </div>
  )
}

export default Container
