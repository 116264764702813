import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { LinkedinSquare, Instagram } from '../Misc/SVG';
import Container from './Container';

const F = ({ data }) => {
  return (
    <footer>
      <Container full>
        <div className="wrapper">
          <small className="copyright">{data.copyright}</small>
          
          <ul className="social">
            <li>
              <a href={data.linkedin_url.url} target="_blank" rel="noopener" aria-label="Why Partners Linkedin Profile">
                <LinkedinSquare/>
              </a>
            </li>
            <li>
              <a href={data.instagram_url.url} target="_blank" rel="noopener" aria-label="Why Partners Instagram Page">
                <Instagram/>
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  );
};

F.propTypes = {
  data: PropTypes.object,
};

const Footer = props => (
  <StaticQuery
    query={graphql`
      query {
        prismicFrame {
          data {
            copyright
            linkedin_url {
              url
            }
            instagram_url {
              url
            }
          }
        }
      }
    `}
    render={data => (
      <F {...data.prismicFrame} {...props} />
    )}
  />
);

export default Footer;
